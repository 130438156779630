<template>
  <div class="col-12 col-md-6 col-lg-4 p-3">
    <div class="card h-100 p-4 pe-2 mx-2 shadow">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <router-link
            v-text="groupData.name"
            :to="'/console/' + groupData.groupID"
            class="text-dark text-decoration-none stretched-link h3 mb-0"
          ></router-link>
        </div>
        <div class="dropdown" style="z-index: 2; width: fit-content">
          <button data-bs-toggle="dropdown" class="btn btn-circle">
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="text-secondary"
            ></font-awesome-icon>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <button class="dropdown-item dropdown-item-danger text-danger" @click="deleteGroup()">
              Delete group
            </button>
          </div>
        </div>
      </div>
      <div class="bg-primary my-2" style="height: 5px; width: 60px;"></div>
      <p v-text="groupData.description"></p>
    </div>
  </div>
</template>

<script>
export default {
  emits : ['deleteGroup'],
  props: {
    groupData: Object,
  },
  methods : {
    deleteGroup () {
      this.$emit("deleteGroup", this.groupData.groupID);
    }
  }
};
</script>