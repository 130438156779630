<template>
  <div
    class="border rounded px-2 py-2 d-flex align-items-center my-2 bg-white"
    :data-videoID="data.videoID"
  >
    <div class="video-item-handle cursor-move">
      <div class="px-2">
        <font-awesome-icon
          :icon="['fas', 'grip-vertical']"
          class="text-secondary fa-fw"
        ></font-awesome-icon>
      </div>
    </div>
    <div class="flex-grow-1 ms-2 text-truncate" v-text="data.name"></div>
    <div class="dropdown">
      <button data-bs-toggle="dropdown" class="btn btn-circle">
        <font-awesome-icon
          :icon="['fas', 'ellipsis-v']"
          class="text-secondary"
        ></font-awesome-icon>
      </button>
      <div class="dropdown-menu dropdown-menu-end">
        <button class="dropdown-item dropdown-item-danger text-danger" @click="removeVideo">
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  emits : ['removeVideo'],
  methods : {
    removeVideo () {
      this.$emit('removeVideo', this.data.videoID);
    }
  }
};
</script>