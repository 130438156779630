<template>
  <div class="container pt-4 h-100">
    <div class="row h-100">
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-center"
      >
        <div>
          <h1>Email a Video Link</h1>
          <h5 class="text-muted">
            Send a video from our website right to your email!
          </h5>
        </div>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-center"
      >
        <div class="border rounded p-4 w-100">
          <sidebar-video v-if="step == 1" @next="next" />
          <sidebar-email v-if="step == 2" @next="next" @previous="reset()" />
          <sidebar-finish v-if="step == 3" :data="{ email : email, video : video }" @reset="reset()" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import sidebarVideo from "@/components/email-link/sidebar-video.vue";
import sidebarEmail from "@/components/email-link/sidebar-email.vue";
import sidebarFinish from "@/components/email-link/sidebar-finish.vue";

export default {
  components : {
    sidebarVideo,
    sidebarEmail,
    sidebarFinish
  },
  data() {
    return {
      step : 1,
      email: "",
      video: {},
    };
  },
  methods : {
    next (data) {
      if (this.step == 1) {
        this.video = data;
      } else if (this.step == 2) {
        this.email = data;
      }
      this.step++;
    },
    reset () {
      this.step = 1;
      this.email = "";
      this.video = {};
    }
  }
};
</script>