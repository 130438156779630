<template>
  <div class="modal fade" tabindex="-1" id="deleteVideoModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete video</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete this video? The video will also be removed from any topics it's currently in.
            <br>
            It's also important to know that the actual Vimeo video won't be deleted - just the copy of it on this site.
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-link hover-underline" data-bs-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-primary px-3" :disabled="loading" @click="deleteVideo()">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";


export default {
  data () {
    return {
      videoID : "",
      loading : false
    };
  },
  emits : ['refresh'],
  methods: {
    async openModal(videoID) {
      this.videoID = videoID;
      var modal = new Modal(document.getElementById("deleteVideoModal"));
      modal.show();
    },
    async deleteVideo() {
      this.loading = true;
      await this.$axios.delete(`/videos/${this.videoID}`);
      this.loading = false;

      var modal = Modal.getInstance(document.getElementById("deleteVideoModal"));
      modal.hide();
      
      this.$emit('refresh');
    },
  },
};
</script>