<template>
  <div class="modal fade" tabindex="-1" id="changePasswordModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change password</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p class="mb-0">Your password should have:</p>
          <ul>
            <li>At least 8 characters</li>
            <li>An uppercase character, and a lowercase character</li>
            <li>A symbol</li>
            <li>A number</li>
          </ul>
          <label class="form-label" for="change-password-old">
            Old password
          </label>

          <input
            type="text"
            id="change-password-old"
            class="form-control"
            v-model="oldPassword"
          />

          <label class="form-label mt-3" for="change-password-new">
            New password
          </label>

          <input
            type="text"
            id="change-password-new"
            class="form-control"
            v-model="newPassword"
          />
          <div class="d-flex justify-content-end my-2">
            <button
              class="btn btn-primary text-nowrap"
              @click="changePassword()"
              :disabled="
                oldPassword.length == 0 || newPassword.length == 0 || loading
              "
            >
              Change password
            </button>
          </div>
          <div
            v-show="err.length > 0"
            class="alert alert-danger"
            v-text="err"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      loading: false,
      err: "",
    };
  },
  methods: {
    async changePassword() {
      this.loading = true;

      var currentUser = await Auth.currentAuthenticatedUser();

      try {
        await Auth.changePassword(
          currentUser,
          this.oldPassword,
          this.newPassword
        );
      } catch (err) {
        this.loading = false;

        switch (err.name) {
          case "NotAuthorizedException":
            this.err = "Sorry, wrong old password!";
            break;
          case "InvalidPasswordException":
            this.err =
              "Sorry, please make sure your new password matches the requirements!";
            break;
          default:
            this.err = err;
        }
        return;
      }

      var modal = Modal.getInstance(
        document.getElementById("changePasswordModal")
      );
      modal.hide();

      this.loading = false;
      this.err = "";
      this.oldPassword = "";
      this.newPassword = "";
    },
  },
  mounted() {
    document
      .getElementById("changePasswordModal")
      .addEventListener("shown.bs.modal", function () {
        document.getElementById("change-password-old").focus();
      });
  },
};
</script>