import { createStore } from 'vuex'
import editor from './modules/editor'
import bunkai from './modules/bunkai'

export default createStore({
  modules : {
    editor : editor,
    bunkai : bunkai
  }
})
