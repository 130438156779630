<template>
  <div class="container pt-4">
    <div class="d-flex justify-content-between">
      <h1 class="mt-3 me-3">Admin Console</h1>
      <password />
    </div>
    <hr class="mx-4" />
    <div>
      <h2>Groups</h2>
      <p>
        Use groups organize videos into categories, like Advanced Degrees or
        TNT.
      </p>
      <button
        class="button btn btn-primary px-3"
        data-bs-toggle="modal"
        data-bs-target="#createGroupModal"
      >
        Create group
      </button>
    </div>
    <loading v-if="loading" />
    <span v-if="!loading && groups.length == 0">No groups</span>
    <div class="row justify-content-center pt-2">
      <groupCard
        v-for="group in groups"
        :key="group.groupID"
        :groupData="group"
        @deleteGroup="openDeleteGroup"
      />
    </div>

    <create-group-modal @refresh="loadData()" />
    <delete-group-modal @refresh="loadData()" ref="deleteGroupModal" />
  </div>
</template>


<script>
import groupCard from "@/components/console/group-card.vue";
import password from "@/components/console/password.vue";
import loading from "@/components/loading.vue";

import createGroupModal from "@/components/console/create-group-modal.vue";
import deleteGroupModal from "@/components/console/delete-group-modal.vue";

export default {
  components: {
    groupCard,
    password,
    loading,
    createGroupModal,
    deleteGroupModal,
  },
  data() {
    return {
      loading: true,
      groups: [],
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.groups = (await this.$axios.get("/groups")).data.Items;
      this.loading = false;
    },
    openDeleteGroup(groupID) {
      this.$refs.deleteGroupModal.openModal(groupID);
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>


<style scoped>
a {
  font-size: 20px;
}
</style>