<template>
  <div>
    <div class="text-center">
      <h1 class="mb-3">Finish setup!</h1>
      <p class="mb-0">Almost there, just finish these last few steps!</p>
    </div>
    <div class="mt-3">
      <div class="mb-3">
        <div class="alert alert-danger" v-show="err != '' && err != 'password-requirements'" v-text="err"></div>
        <div class="alert alert-danger" v-show="err == 'password-requirements'">
          Hey, your password should have:
          <ul class="mb-0">
            <li>An uppercase letter</li>
            <li>A lowercase letter</li>
            <li>A number</li>
            <li>A symbol</li>
          </ul>
        </div>
        <label class="form-label" for="name"> Name </label>
        <input
          type="text"
          id="name"
          placeholder="Hanshi Kovar"
          class="form-control"
          v-model="newName"
          maxlength="100"
          @keyup.enter="changePassword()"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="password"> New Password </label>
        <input
          type="password"
          id="password"
          class="form-control"
          v-model="newPassword"
          maxlength="99"
          @keyup.enter="changePassword()"
        />
      </div>
    </div>
    <div class="d-grid gap-2">
      <button class="btn btn-primary" @click="changePassword()" :disabled="loading">Sign in</button>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      newName: "",
      newPassword: "",
      err : "",
      loading : false
    };
  },
  mounted () {
    document.getElementById("name").focus();
  },
  props: {
    user: Object,
  },
  emits : ["redirect"],
  methods: {
    showError (msg) {
      this.err = msg;
      this.loading = false;
    },
    async changePassword() {
      var passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$")
      if (this.newName == "") {
        this.showError("Hey, don't forget to specify your name!");
        return;
      } else if (this.newPassword == "") {
        this.showError("Hey, don't forget a password!");
        return;
      } else if (!passwordRegex.test(this.newPassword)) {
        this.showError("password-requirements"); //Changes to different alert
        return;
      }

      this.loading = true;

      try {
        var newPassword = await Auth.completeNewPassword(
          this.$props.user,
          this.newPassword,
          {
            name: this.newName,
          }
        );
        console.log(newPassword);
        this.$emit('redirect');
      } catch (err) {
        this.showError("Hmm, something went wrong!");
        throw err;
      }
    },
  },
};
</script>