<template>
  <div>
    <button class="btn btn-link p-0 mb-1 hover-underline" @click="back()">
      <font-awesome-icon
        :icon="['fas', 'angle-left']"
        class="me-1"
      ></font-awesome-icon>
      Back
    </button>
    <div class="px-3">
      <h4 class="mb-3">Copy and paste your Vimeo link below!</h4>
      <div class="d-flex justify-content-center">
        <div class="input-group" style="max-width: 400px">
          <span class="input-group-text">https://vimeo.com/</span>
          <input
            type="text"
            v-model="url"
            class="form-control"
            placeholder="11111"
            maxlength="20"
          />
        </div>
        <button
          :disabled="loading || url.length == 0"
          @click="next()"
          class="btn btn-primary ms-2 px-3"
        >
          Next
        </button>
      </div>

      <loading v-if="loading" />
      <div class="pt-4" v-if="url.length > 0">
        <div class="ratio ratio-16x9 mx-auto" style="max-width: 600px">
          <iframe
            @load="loading = false"
            :src="
              this.url
                ? 'https://player.vimeo.com/video/' +
                  this.url +
                  '?badge=0&autopause=0&player_id=0'
                : ''
            "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  components: {
    loading,
  },
  data() {
    return {
      url: "",
      loading: false,
    };
  },
  emits: ["back", "next"],
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next", {
        component: "upload-link",
        url : this.url,
      });
    },
  },
  watch: {
    url() {
      if (this.url.length > 0) {
        this.loading = true;
      }
    },
  },
};
</script>