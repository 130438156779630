import { Auth } from 'aws-amplify'

export default async function () { //Checks for no or expired credentials
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (err) {
        if (err == "The user is not authenticated") {
            return false;
        } else {
            throw err;
        }
    }
}