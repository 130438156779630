<template>
    <div class='container-sm pt-4' style="max-width: 800px;">
        <div class="mb-4">
            <h1>Bunkai</h1>
            <div>Bunkai is an app that allows all students to access curriculum, instead of Black Belt Prep students only.
                Edit this cycle's curriculum below!</div>
        </div>

        <div class="row mb-4">
            <div class="col-3">
                <h2 class="h4">Rank</h2>
                <Sidenav :ranks="ranks" v-model:selected="selected" />
            </div>
            <div class="col-9">
                <h2 class="h4">Videos</h2>
                <loading v-if="loading" />
                <div v-else>
                    <div class="mb-3">
                        <draggable v-model="videos" item-key="videoID" handle=".video-item-handle" ghost-class="ghost"
                            force-fallback="true">
                            <template #item="{ element }">
                                <video-item :data="element" @removeVideo="removeVideo(element.videoID)"></video-item>
                            </template>
                        </draggable>
                    </div>
                    <div class="d-grid">
                        <button class="btn btn-outline-primary" @click="openModal">Add video</button>
                    </div>
                    <add-videos-modal-bunkai ref="addVideosModal" @add-videos="addVideos" />
                </div>
            </div>
        </div>

        <div class="card px-3 py-3 rounded">
            <div class='d-flex justify-content-between align-items-center'>
                <div class="fst-italic">Publish your changes to save your work</div>
                <button class="btn btn-primary" :disabled="!modified || publishing" @click="publish()">
                    Publish changes
                </button>
            </div>
        </div>
    </div>

    <div class="position-absolute end-0 bottom-0 pb-5 pe-5">
        <div class="toast align-items-center bg-primary text-white px-2 py-2" id="bunkai-publish-toast" role="alert"
            aria-live="assertive" aria-atomic="true">
            <div class="d-flex align-items-center">
                <div class="toast-body fs-6">Changes published!</div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithCredential, OAuthProvider } from "firebase/auth";
import { collection, doc, getDocs, query, getFirestore, orderBy, writeBatch } from 'firebase/firestore';
import { Auth } from 'aws-amplify';

import { Toast } from 'bootstrap';

import videoItem from '@/components/general/video-item.vue';
import draggable from 'vuedraggable';
import addVideosModalBunkai from '@/components/bunkai/add-videos-modal-bunkai.vue';

import Sidenav from '@/components/bunkai/sidenav.vue';
import loading from '@/components/loading.vue';

export default {
    components: {
        Sidenav,
        videoItem,
        draggable,
        addVideosModalBunkai,
        loading
    },
    data() {
        return {
            ranks: [],
            selected: 0,
            videos: [],
            modified: false,
            publishing: false,
            loading: true
        };
    },
    methods: {
        async oidcAuth() {
            let cognito = await Auth.currentSession();
            let cognitoIdToken = cognito.getIdToken().getJwtToken();

            console.log(cognitoIdToken);

            const provider = new OAuthProvider("oidc.cognito-dev");
            await signInWithCredential(getAuth(), provider.credential({
                idToken: cognitoIdToken,
            }));
        },
        async getVideos() {
            this.loading = true;

            let result = [];
            for (let videoID of this.ranks[this.selected].videos) {
                let video = await this.$axios.get(`/videos/${videoID}`);
                result.push(video.data);
            }

            this.videos = result;

            this.$nextTick(() => this.loading = false);
        },
        async getRanks() {
            let db = getFirestore();

            let ranksRef = collection(db, 'ranks');
            let ranks = await getDocs(query(ranksRef, orderBy('index')));

            this.ranks = ranks.docs.map(doc => {
                return {
                    rankID: doc.id,
                    ...doc.data()
                };
            });
        },
        openModal() {
            this.$refs.addVideosModal.openModal(this.videos);
        },
        addVideos(videos) {
            this.videos = this.videos.concat(videos);
        },
        removeVideo(videoID) {
            this.videos = this.videos.filter(video => video.videoID != videoID);
        },
        async publish() {
            this.publishing = true;

            let db = getFirestore();

            const batch = writeBatch(db);

            for (let [key, value] of Object.entries(this.$store.state.bunkai.videoChanges)) {
                batch.update(doc(db, 'ranks', key), {
                    videos: value
                });
            }

            await batch.commit();

            this.$store.commit('bunkai/resetVideoChanges');

            this.publishing = false;
            this.modified = false;

            let toast = new Toast(document.getElementById('bunkai-publish-toast'));
            toast.show();
        }
    },
    async mounted() {
        await this.oidcAuth();

        await this.getRanks();

        await this.getVideos();
    },
    watch: {
        selected() {
            this.getVideos();
        },
        videos() {
            if (this.loading) {
                return;
            }

            this.modified = true;

            this.$store.commit('bunkai/setVideoChanges', {
                rankID: this.ranks[this.selected].rankID,
                videos: this.videos.map(video => video.videoID)
            });
        }
    }
}
</script>