<template>
  <div>
    <div v-show="!edit">
      <div class="d-flex align-items-center">
        <h4 class="mb-0" v-if="showText" v-text="value"></h4>
        <button
          :class="'btn btn-link p-0 hover-underline' + (showText ? ' ms-2' : '')"
          @click="openEdit()"
          v-text="buttonText"
        ></button>
      </div>
    </div>
    <div :class="inputGroupText !== '' ? 'input-group' : ''">
      <span
        v-if="inputGroupText !== '' && edit"
        class="input-group-text"
        v-text="inputGroupText"
      ></span>
      <input
        type="text"
        class="form-control"
        style="max-width: 400px"
        :maxlength="maxlength !== 0 ? maxlength : null"
        v-show="edit"
        v-model="value"
        @keyup.enter="
          $refs.input.value.length > 0 ? (edit = false) : null /* calls blur */
        "
        @blur="save()"
        ref="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      edit: false,
      value: "",
    };
  },
  emits: ["save"],
  methods: {
    openEdit() {
      this.edit = true; //TODO - jQuery add focus? Event parameter?
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    save() {
      if (this.value.length == 0) {
        return;
      }

      this.edit = false;
      this.$emit("save", this.value);
    },
  },
  mounted() {
    this.value = this.initialValue; //One time
  },
  props: {
    initialValue: String,
    buttonText: {
      default: "Edit",
      type: String,
    },
    showText: {
      default: true,
      type: Boolean,
    },
    inputGroupText: {
      default: "",
      type: String,
    },
    maxlength : {
      default : 0,
      type : Number
    }
  },
};
</script>