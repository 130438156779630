<template>
  <div>
    <loading v-if="loading" class="pb-5" />
    <div v-if="!loading">
      <h3>All finished! 👏</h3>
      <div>
        We just sent the video - check your email inbox!<br />Didn't send? Check
        your spam or junk folder.
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button class="btn btn-outline-primary" @click="reset()">
          Send another email
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  components: {
    loading,
  },
  data() {
    return {
      loading: true,
    };
  },
  emits : ['reset'],
  async mounted() {
    await this.$axios.post("/app/emaillink", this.data);
    this.loading = false;
  },
  methods : {
      reset () {
          this.$emit('reset');
      }
  },
  props: ["data"],
};
</script>