<template>
  <div class="d-flex align-items-center justify-content-center h-100 p-4">
    <div id="content" class="shadow-lg rounded p-5 overflow-auto">
      <signIn v-if="view == 'signIn'" @change-password="changePassword" @redirect="redirect" />
      <changePassword :user="changePasswordUser" v-if="view == 'changePassword'" @redirect="redirect" />
    </div>
  </div>
</template>

<style scoped>
  #content {
    max-width: 450px;
    max-height: 100%;
    transition: height 2s;
  }
</style>

<script>
import { Auth } from "aws-amplify";
import signIn from "@/components/signin/signin.vue"
import changePassword from "@/components/signin/change-password.vue"


export default {
  components : {
    signIn,
    changePassword
  },
  data () {
    return {
      view : "signIn",
      changePasswordUser : {}
    }
  },
  methods: {
    async credentials() {
      console.log(await Auth.currentCredentials()); //Automatically gets credentials from identity pool :)
    },
    changeView (view) {
      this.view = view;
    },
    changePassword (data) {
      this.changePasswordUser = data; //User object returned from signin
      this.changeView('changePassword');
    },
    redirect () { //Finish
      var redirectURL = this.$route.query.redirect;
      this.$router.push(redirectURL ? decodeURIComponent(redirectURL) : "/console");
    }
  },
};
</script>