<template>
  <div class="modal fade" tabindex="-1" id="publishModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Publish changes</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>
            Publishing changes will make all changes you've made visible to students.
            Send it to the internet!
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-link hover-underline" data-bs-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-primary px-3" :disabled="loading" @click="publish()">
            {{ loading ? "Publishing..." : "Publish" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";


export default {
  data () {
    return {
      loading : false
    };
  },
  methods: {
    async publish() {
      this.loading = true;
      
      await this.$axios.patch(`/groups/${this.$route.params.groupID}/topics/publish`);

      this.loading = false;

      var modal = Modal.getInstance(document.getElementById("publishModal"));
      modal.hide();
    },
  },
};
</script>