<template>
  <div>
    <h4 class="mb-2">Add video info</h4>
    <label class="form-label" for="create-video-name">Name</label>
    <input type="text" id="create-video-name" class="form-control" v-model="name" maxlength="150" />
    <label class="form-label mt-3" for="create-video-content"
      >Description</label
    >
    <markdown-editor
      :toggleEdit="false"
      textareaID="create-video-content"
      ref="content"
      v-model:value="description"
      :maxlength="1500"
    />

    <div class="d-grid gap-2 mt-2 mx-auto" style="max-width: 400px;">
      <button class="btn btn-primary" :disabled="name.length == 0 || description.length == 0" @click="next()">Create video</button>
    </div>
  </div>
</template>

<script>
import markdownEditor from "@/components/markdown-editor.vue";

export default {
  components: {
    markdownEditor,
  },
  data () {
      return {
          name : "",
          description : ""
      };
  },
  emits : ['next'],
  methods : {
      next () {
          this.$emit("next", {
              component : "video-info",
              name : this.name,
              content : this.description
          });
      },
  }
};
</script>