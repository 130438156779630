<template>
  <div>
    <button class="btn btn-link p-0 hover-underline" @click="back()">
      <font-awesome-icon
        :icon="['fas', 'angle-left']"
        class="me-1"
      ></font-awesome-icon>
      Back
    </button>
    <!-- On drag to child, dragenter calls, then dragleave (from parent), so with the initial drag into self, dragging should always equal 1 -->
    <div
      @drop="dropFile"
      @dragenter="dragging++"
      @dragleave="dragging--"
      :class="'px-3 pt-1 rounded' + (dragging > 0 ? ' dragging' : '')"
    >
      <h4 v-text="loading ? 'Uploading...' : 'Upload a file'"></h4>
      <div class="d-flex justify-content-center">
        <div class="w-100" v-show="loading">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="'width: ' + progress + '%'"
            ></div>
          </div>
        </div>
        <div class="py-4" v-show="!loading">
          Drag and drop a file here, or
          <button
            class="btn btn-link hover-underline p-0 align-baseline"
            @click="selectFile()"
          >
            select a file
          </button>
        </div>

        <input
          class="d-none"
          type="file"
          ref="input"
          accept="video/*"
          @change="upload()"
        />
      </div>
    </div>
    <div
          class="alert alert-danger mt-3 mb-0 mx-3"
          v-if="uploadErr"
          v-text="uploadErr"
        ></div>
  </div>
</template>

<style lang="scss" scoped>
.dragging {
  background-color: #eee;
}
</style>

<script>
import * as tus from "tus-js-client";

export default {
  data() {
    return {
      loading: false,
      dragging: 0,
      progress: 0,
      uploadErr: "",
    };
  },
  emits: ["back", "next"],
  methods: {
    back() {
      this.$emit("back");
    },
    selectFile() {
      this.$refs.input.click();
    },
    async dropFile(event) {
      if (this.loading) {
        return;
      }

      console.log(event.dataTransfer.files[0].type);

      if (event.dataTransfer.files.length > 1) {
        this.uploadErr = "Please upload only one file!";
        this.dragging = 0;
        return;
      }

      if (!/video\/(.*)/.test(event.dataTransfer.files[0].type)) {
        this.uploadErr = "Please upload a video file!";
        this.dragging = 0;
        return;
      }

      this.$refs.input.files = event.dataTransfer.files;
      await this.upload();
    },
    async upload() {
      console.log("Uploading!");

      var video = (await this.$axios.post('/app/vimeo/videos', {
          name : this.$refs.input.files[0].name,
          size : this.$refs.input.files[0].size.toString()
      })).data; //uploadURL, videoID

      console.log(video);

      var vm = this;

      this.dragging = 0;
      this.loading = true;

      var upload = new tus.Upload(this.$refs.input.files[0], {
        uploadUrl: video.uploadURL,
        onError(err) {
          console.log("Failed because: " + err);
          vm.uploadErr = "Sorry, something went wrong with the upload!";
          throw err;
        },
        onProgress(bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
          vm.progress = percentage;
          console.log(`${percentage}%`);
          //console.log(bytesUploaded, bytesTotal, percentage + "%");
        },
        async onSuccess() {
          await vm.$axios.put(`/app/vimeo/videos/${video.videoID}/embed`, {
            url : "kovarslaguna.com"
          });
          
          console.log("Finished!");
          this.loading = false;
          vm.$emit("next", {
            component: "upload-file",
            url: video.videoID,
          });
        },
      });

      upload.start();
    },
  },
};
</script>