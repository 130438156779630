export default {
    async saveEditName(name) {
        this.$store.commit("editor/updateName", {
            topicID: this.topicID,
            name: name,
        });
        
        this.saving(true);
        await this.$axios.patch(`/groups/${this.$route.params.groupID}/topics/${this.topicID}`, {
            name: name
        });
        this.saving(false);
    },
    /*openEditContent() {
        //https://jsfiddle.net/ekjyupst/1/
        this.editContent = true;
    },*/
    async saveEditContent(content) {
        this.$store.commit("editor/updateContent", {
            topicID: this.topicID,
            content: content,
        });

        this.saving(true);
        await this.$axios.patch(`/groups/${this.$route.params.groupID}/topics/${this.topicID}`, {
            content : content
        });
        this.saving(false);
    },
    addVideos() {
        this.$emit("addVideos", { topicID: this.topicID });
    },
    expandTopic() {
        this.$store.commit("editor/expand", this.topicID);
    },
    deleteTopic() {
        //this.$store.commit("editor/deleteTopic", this.topicID);
        this.$emit("deleteTopic", { topicID: this.topicID });
    },
    saving (value) {
        this.$store.state.editor.saving = value;
    }
}