<template>
  <div>
    <h3>Select a Video</h3>
    <label class="form-label mb-1" for="sidebar-group">Group</label>
    <select v-model="selected.groupID" id="sidebar-group" class="form-select" @change="changeGroup()">
      <option value="-">Choose a Group</option>
      <option
        v-for="group in groups"
        :key="group.groupID"
        :value="group.groupID"
        v-text="group.name"
      ></option>
    </select>

    <label class="form-label mt-3 mb-1" for="sidebar-topic">Topic</label>
    <select v-model="selected.topicID" id="sidebar-topic" class="form-select" @change="changeTopic()">
      <option value="-">Choose a Topic</option>
      <option v-for="topic in topics" :key="topic.topicID" :value="topic.topicID" v-text="topic.name"></option>
    </select>

    <label class="form-label mt-3 mb-1" for="sidebar-video">Video</label>
    <select v-model="selected.videoID" id="sidebar-video" class="form-select" @change="changeVideo()">
      <option value="-">Choose a Video</option>
      <option v-for="video in videos" :key="video.videoID" :value="video.videoID" v-text="video.name"></option>
    </select>

    <div class="d-grid gap-2 mt-4">
      <button class="btn btn-primary" @click="next()" :disabled="nextDisabled">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groups: [],
      topics : [],
      videos : [],
      nextDisabled: true,
      selected : {
          groupID : '-',
          topicID : '-',
          videoID : '-'
      }
    };
  },
  emits : ["next"],
  async mounted() {
    this.groups = (await this.$axios.get("/groups")).data.Items;
  },
  methods: {
    async changeGroup() {
      if (this.selected.groupID == "-") {
        this.nextDisabled = false;
        return;
      }

      this.selected.topicID = "-";
      this.selected.videoID = "-";

      this.topics = [];
      this.videos = [];
      this.nextDisabled = true;

      this.topics = (await this.$axios.get(`/groups/${this.selected.groupID}/topics`)).data.topics;
    },
    async changeTopic() {
      if (this.selected.topicID == "-") {
        this.nextDisabled = false;
        return;
      }

      this.selected.videoID = "-"; 
      this.videos = [];
      this.nextDisabled = true;

      this.videos = (await this.$axios.get(`/groups/${this.selected.groupID}/topics/${this.selected.topicID}/videos`)).data.topicvideos;
    },
    changeVideo() {
      if (this.selected.videoID == "-") {
        this.nextDisabled = true;
        return;
      }

      this.nextDisabled = false;
    },
    next () {
      this.$emit("next", this.selected);
    }
  },
};
</script>