<template>
  <div>
    <div
      class="border rounded shadow-sm my-5 bg-white d-flex align-items-center"
      data-topicID="asdf1"
    >
      <div class="topic-handle cursor-move py-3">
        <div class="px-2">
          <font-awesome-icon
            :icon="['fas', 'grip-vertical']"
            class="text-secondary fa-fw"
          ></font-awesome-icon>
        </div>
      </div>
      <div class="py-4 ps-2 pe-3 flex-grow-1" style="min-width: 0">
        <div v-show="!expand" @click="expandTopic()" class="cursor-pointer">
          <div class="d-flex align-items-center">
            <h4
              class="mb-0 text-truncate"
              style="width: fit-content"
              v-text="topic.name"
            ></h4>
            <p class="text-muted mx-2 mb-0">·</p>
            <p class="text-muted mb-0" style="min-width: fit-content">
              {{ videos.length }} {{ videos.length == 1 ? "video" : "videos" }}
            </p>
          </div>
        </div>

        <!-- -->

        <div v-show="expand">
          <div class="d-flex align-items-center justify-content-between">
            <div class="w-100">
              <input-editor :initialValue="topic.name" @save="saveEditName" :maxlength="150" />
            </div>
            <div class="dropdown d-inline-block">
              <button data-bs-toggle="dropdown" class="btn btn-circle">
                <font-awesome-icon
                  :icon="['fas', 'ellipsis-v']"
                  class="text-secondary"
                ></font-awesome-icon>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <button class="dropdown-item dropdown-item-danger text-danger" @click="deleteTopic()">
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="container-fluid my-3">
            <div class="row">
              <div class="col-12 col-lg-6">
                <markdown-editor v-model:value="topic.content" label="Description" @save="saveEditContent" :maxlength="1500" />
              </div>
              <div class="col-12 d-lg-none">
                <hr class="my-4" />
              </div>
              <div class="col-12 col-lg-6">
                <h6>Videos</h6>
                <div class="ps-2 pe-4">
                  <div class="sort-videos" data-sort-type="videos">
                    <div class="text-center text-muted fst-italic py-1" v-if="videos.length == 0">No videos yet, add some below!</div>
                    <draggable
                      v-model="videos"
                      item-key="videoID"
                      handle=".video-item-handle"
                      ghost-class="ghost"
                      force-fallback="true"
                    >
                      <template #item="{ element }">
                        <video-item-editor :data="element" :topicID="topicID"></video-item-editor>
                      </template>
                    </draggable>
                  </div>
                  <div class="d-grid mt-3">
                    <button
                      class="btn btn-outline-primary mx-5"
                      @click="addVideos()"
                    >
                      Add video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!expand">
        <div class="dropdown ml-2 d-inline-block pe-3">
          <button data-bs-toggle="dropdown" class="btn btn-circle">
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="text-secondary"
            ></font-awesome-icon>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <button class="dropdown-item dropdown-item-danger text-danger" @click="deleteTopic()">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Don't forget - all the methods are in a separate file :)


import videoItemEditor from "@/components/editor/video-item-editor.vue";

import draggable from "vuedraggable";

import inputEditor from '@/components/input-editor.vue';
import markdownEditor from '@/components/markdown-editor.vue';

import methods from './topic-methods.js'




export default {
  components: {
    videoItemEditor,
    draggable,
    markdownEditor,
    inputEditor
  },
  computed: {
    topic() {
      return this.$store.getters['editor/topic'](this.topicID);
    },
    videos: {
      get() {
        var data = this.$store.getters['editor/videos'](this.topicID);
        return data ? data : [];
      },
      async set(value) {
        this.$store.commit("editor/updateVideos", {
          topicID: this.topicID,
          videos: value,
        });

        await this.$axios.put(`/groups/${this.$route.params.groupID}/topics/${this.topicID}/videos/order`, {
          order : value.map(function (item) {
            return item.videoID
          })
        });
      },
    },
    expand() {
      return this.$store.state.editor.expandedTopic == this.topicID;
    }
  },
  data() {
    return {
      editName: false
    };
  },
  emits: ["addVideos", "deleteTopic"],
  methods,
  async mounted() {
    this.$store.commit("editor/loadTopicVideos", {
      topicID: this.topicID,
      videos: (
        await this.$axios.get(
          `/groups/${this.$route.params.groupID}/topics/${this.topicID}/videos`
        )
      ).data.topicvideos,
    });
  },
  props: {
    topicID: String,
  },
};
</script>

<style scoped>
#topic {
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  border: 1px solid black;
}
.side {
  border: 1px solid black;
  padding: 20px;
  margin: 20px;
  float: left;
}
</style>

<style>
.no-p-margin p {
  margin: 0 !important;
}
</style>