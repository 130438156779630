<template>
    <div>
        <ul class="nav nav-pills flex-column">
            <li class="nav-item mb-1" v-for="(rank, index) in ranks" :key="index" @click="setSelected(index)">
                <a href="#" :class="`nav-link ${selected == index && 'active'}`">{{ rank.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    methods : {
        setSelected (index) {
            this.$emit('update:selected', index);
        }
    },
    props : ['ranks', 'selected'],
}
</script>