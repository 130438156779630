import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'


//import './../node_modules/@popperjs/core/dist/cjs/popper.js'




import isAuthenticated from './scripts/isAuthenticated.ts'

import { aws4Interceptor } from 'aws4-axios'

import Auth from '@aws-amplify/auth' //import { Auth } from 'aws-amplify'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisV, faGripVertical, faBold, faLink, faItalic, faAngleLeft, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

var app = createApp(App).use(store);


app.use(router).mount('#app');





import 'bootstrap'

import store from './store'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIxpUrvXnze6Vy-Fd7SSCERgKBSOUcnQg",
  authDomain: "kovars-bunkai.firebaseapp.com",
  projectId: "kovars-bunkai",
  storageBucket: "kovars-bunkai.appspot.com",
  messagingSenderId: "1092061203289",
  appId: "1:1092061203289:web:cd432dfb3a2cbe36de1a48"
};

// Initialize Firebase
initializeApp(firebaseConfig);



library.add(faEllipsisV, faGripVertical, faBold, faLink, faItalic, faAngleLeft, faExternalLinkAlt)
app.component('font-awesome-icon', FontAwesomeIcon)



var axiosInstance = axios.create({
    baseURL : "https://api.kovarslaguna.com"
});


axiosInstance.interceptors.request.use(async function (request) {
    console.log(`Interceptor for ${request.method} ${request.url}`);

    if (!await isAuthenticated()) {
        console.log(router.currentRoute.value.fullPath);
        router.push(`/signin?redirect=${encodeURIComponent(router.currentRoute.value.fullPath)}`);
        return { //Request will still execute even on redirect, so cancel (goes to errorHandler)
            request,
            cancelToken: new axios.CancelToken(function (cancel) {
                cancel('Redirecting to /password (axios interceptor)');
            })
        }
    }

    console.log("No redirect necessary");

    //No public endpoints except POST /app/session, but not necessary for admin

    var credentials = await Auth.currentCredentials();
    
    console.log(credentials);
    
    var interceptor = aws4Interceptor({
        region: "us-west-1",
        service: "execute-api"
    }, {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken
    });
    return interceptor(request);


});

app.config.globalProperties.$axios = axiosInstance;

export {
    app
}