<template>
    <add-videos-modal @addVideos="addVideos" ref="addVideosModal" />
</template>

<script>
import addVideosModal from './../general/add-videos-modal.vue';

export default {
    components: {
        addVideosModal
    },
    data () {
        return {
            topicID : ''
        };
    },
    methods: {
        openModal(topicID) {
            this.topicID = topicID;

            let vm = this;
            let filter = (item) => {
                return vm.$store.getters['editor/videoIDs'](this.topicID).indexOf(item.videoID) == -1;
                //If true (not found), it should stay in the list
            }

            this.$refs.addVideosModal.openModal(filter);
        },


        async addVideos(selectedVideos) {
            this.$store.commit("editor/addVideos", {
                topicID: this.topicID,
                videos: selectedVideos.map(function (item) { //filters out selected : true
                    return {
                        name: item.name,
                        videoID: item.videoID
                    };
                }),
            });

            this.$refs.addVideosModal.closeModal();

            await this.$axios.patch(`/groups/${this.$route.params.groupID}/topics/${this.topicID}/videos`, {
                videoIDs: selectedVideos.map(function (item) {
                    return item.videoID;
                })
            });
        },
    }
}
</script>