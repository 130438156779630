export default {
    namespaced : true,
    state : {
        videoChanges : {} //key is rank id
    },
    mutations : {
        setVideoChanges(state, { rankID, videos }) {
            state.videoChanges[rankID] = videos;
        },
        resetVideoChanges(state) {
            state.videoChanges = {};
        }
    }
}