<template>
  <div class="container pt-4">
    <h2 class="mb-3">My Profile</h2>
    <div class="mt-2">
      <b>Name: </b> {{ user.name }}
    </div>
    <div class="mt-2">
      <b>Email: </b> {{ user.email }}
    </div>
    <button class="btn btn-link mt-2 mb-3 d-block p-0" data-bs-toggle="modal" data-bs-target="#changePasswordModal">Change password</button>
    <button class="btn btn-outline-primary mt-2" @click="signOut()">Sign out</button>

    <changePasswordModal />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

import changePasswordModal from '@/components/profile/change-password-modal.vue';

export default {
  components : {
    changePasswordModal
  },
  data () {
    return {
      user : {}
    };
  },
  methods : {
    async signOut () {
        await Auth.signOut();
        this.$router.push('/signin');
    }
  },
  async mounted () {
    var currentUser = await Auth.currentAuthenticatedUser();
    
    var userAttributes = await Auth.userAttributes(currentUser);
    // [ { "Name" : " ", "Value" : " " }]
    var vm = this;
    userAttributes.forEach(function (item) {
      vm.user[item.Name] = item.Value;
    });
  }
}
</script>