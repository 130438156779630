<template>
  <div class="container">
    <div class="pt-5 d-flex justify-content-between align-items-center">
      <h1 v-text="groupName"></h1>
      <div>
        <a
          tabindex="0"
          class="text-muted fst-italic pe-3 text-decoration-none"
          role="button"
          data-bs-toggle="popover"
          data-bs-trigger="focus"
          data-bs-placement="bottom"
          data-bs-content="Your changes are saved automatically as a draft until you're ready to publish changes to students.<br />The draft changes are saved in the cloud, so you'll still have your work if you close the tab, or if you start working on another device."
          data-bs-html="true"
        >
          {{ $store.state.editor.saving ? "Saving..." : "Changes saved" }}</a
        >
        <button
          class="btn btn-primary px-3"
          data-bs-toggle="modal"
          data-bs-target="#publishModal"
        >
          Publish changes
        </button>
        <!-- Modal in separate component, but it should still have the id -->
      </div>
    </div>
    <hr />
    <div class="dropdown">
      <button
        class="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        New topic
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a class="dropdown-item" href="#" @click="newBlankTopic()"
            >Blank topic</a
          >
        </li>
        <li>
          <a class="dropdown-item" href="#" @click="importTopics()"
            >Import existing topic</a
          >
        </li>
      </ul>
    </div>

    <loading v-if="loading" />
    <div
      class="text-center text-muted fst-italic mt-3"
      v-if="!loading && topics.length == 0"
    >
      No topics yet, add one above!
    </div>

    <div class="pb-3">
      <draggable v-model="topics" item-key="topicID" handle=".topic-handle" ghost-class="ghost" force-fallback="true" @choose="closeExpandedTopic">
        <template #item="{ element }">
          <topic :topicID="element.topicID" @add-videos="addVideosModal" @delete-topic="deleteTopicModal" />
        </template>
      </draggable>
    </div>
    <add-videos-modal-editor ref="addVideosModal" />

    <publish-modal ref="publishModal" />
    <import-topics-modal ref="importTopicsModal" @refresh-data="loadData" />
    <delete-topic-modal ref="deleteTopicModal" />
  </div>
</template>


<style>
.ghost {
  background-color: #eeeeee !important;
  border-radius: 0.25rem;
}

.ghost * {
  visibility: hidden;
}

</style>

<script>
import topic from "@/components/editor/topic.vue";
import loading from "@/components/loading.vue";

import addVideosModalEditor from "@/components/editor/add-videos-modal-editor.vue";
import importTopicsModal from "@/components/editor/import-topics-modal.vue";
import publishModal from "@/components/editor/publish-modal.vue";
import deleteTopicModal from "@/components/editor/delete-topic-modal.vue";

import draggable from "vuedraggable";

//import editorData from "./../../editor-data.json";

import { Popover } from "bootstrap";

export default {
  components: {
    topic,
    loading,
    addVideosModalEditor,
    draggable,
    publishModal,
    importTopicsModal,
    deleteTopicModal,
  },
  computed: {
    topics: {
      get() {
        return this.$store.state.editor.topics;
      },
      async set(value) {
        this.$store.commit("editor/editTopics", value);

        await this.$axios.put(`/groups/${this.$route.params.groupID}/topics/order`, {
          order : value.map(function (item) {
            return item.topicID
          })
        });
      },
    },
  },
  data() {
    return {
      loading: true,
      groupName: "---",
    };
  },
  methods: {
    async addVideosModal(data) {
      await this.$refs.addVideosModal.openModal(data.topicID);
    },
    async deleteTopicModal(data) {
      await this.$refs.deleteTopicModal.openModal(data.topicID);
    },
    closeExpandedTopic(event) {
      this.$store.state.editor.expandedTopic = "";
      console.log(event);
    },
    async newBlankTopic() {
      var topicID = (
        await this.$axios.post(`/groups/${this.$route.params.groupID}/topics`, {
          name: "New topic",
          content: "*Add a description here!*",
        })
      ).data.topicID;

      this.$store.commit("editor/newBlankTopic", topicID);
    },
    importTopics() {
      this.$refs.importTopicsModal.openModal();
    },
    resetData() {
      this.$store.commit("editor/editTopics", []);
    },
    async loadData() {
      this.loading = true;
      this.resetData();

      this.groupName = (
        await this.$axios.get(`/groups/${this.$route.params.groupID}`)
      ).data.name;

      var data = (
        await this.$axios.get(`/groups/${this.$route.params.groupID}/topics`)
      ).data.topics;

      //var data = editorData.data; //Offline

      data = data.map(function (item) {
        return {
          //Remove video order list attribute
          name: item.name,
          content: item.content,
          topicID: item.topicID,
        };
      });
      this.$store.commit("editor/editTopics", data);
      this.loading = false;
    },
  },
  beforeMount () {
    this.resetData();
  },
  async mounted() {
    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl);
    });

    await this.loadData();
  },
};
</script>