<template>
  <div class="container pt-4">
    <h1>Videos</h1>
    <p>Upload videos here first, then use them into the editor!</p>
    <button
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#createVideoModal"
    >
      Upload video
    </button>

    <loading v-if="loading" />

    <div class="text-center fst-italic" v-if="!loading && videos.length == 0">No videos yet, create one above!</div>
    
    <table
      class="table table-hover align-middle mx-auto mt-3 mb-3"
      style="max-width: 800px"
      v-show="!loading && videos.length > 0"
    >
      <thead>
        <tr>
          <td class="fw-bold" colspan="3">Videos</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="video in sortedVideos" :key="video.videoID" class="cursor-pointer">
          <td @click="editVideo(video.videoID)">
            <div v-text="video.name" class="text-truncate"></div>
          </td>
          <td @click="editVideo(video.videoID)">
            Used in {{ video.in ? video.in.length : "-" }}
            {{
              video.in
                ? video.in.length == 1
                  ? "location"
                  : "locations"
                : "locations"
            }}
          </td>
          <td class="td-min-width">
            <div class="dropdown float-end">
              <button data-bs-toggle="dropdown" class="btn btn-circle">
                <font-awesome-icon
                  :icon="['fas', 'ellipsis-v']"
                  class="text-secondary"
                ></font-awesome-icon>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <button class="dropdown-item dropdown-item-danger text-danger" @click="deleteVideo(video.videoID)">
                  Delete video
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <edit-video-modal ref="editVideoModal" @refresh="loadVideos()" />
    <delete-video-modal ref="deleteVideoModal" @refresh="loadVideos()" />
    <create-video-modal @refresh="loadVideos()" />
  </div>
</template>

<style scoped>
.td-min-width {
  width: 1%;
  white-space: nowrap;
}
</style>

<script>
import editVideoModal from "@/components/videos/edit-video-modal.vue";
import createVideoModal from "@/components/videos/create-video-modal.vue";
import deleteVideoModal from "@/components/videos/delete-video-modal.vue";

import loading from "@/components/loading.vue";

export default {
  components: {
    editVideoModal,
    createVideoModal,
    deleteVideoModal,
    loading,
  },
  computed: {
    sortedVideos() {
      return [...this.videos].sort(function (a, b) {
        a = a.name.toUpperCase();
        b = b.name.toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
    },
  },
  data() {
    return {
      loading: true,
      videos: [],
    };
  },
  methods: {
    async editVideo(videoID) {
      await this.$refs.editVideoModal.openModal(videoID);
    },
    async loadVideos() {
      this.loading = true;

      this.videos = (await this.$axios.get("/videos")).data.Items;

      this.loading = false;

      var vm = this;
      this.videos.forEach(async function (item, index) {
        vm.videos[index].in = (
          await vm.$axios.get(`/videos/${item.videoID}/in`)
        ).data.Items;
      });
    },
    deleteVideo (videoID) {
      this.$refs.deleteVideoModal.openModal(videoID);
    }
  },
  async mounted() {
    this.loadVideos();

    //Same modal being used for each video opened, so applied only once
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://player.vimeo.com/api/player.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>