<template>
    <add-videos-modal @add-videos="addVideos" ref="addVideosModal" />
</template>

<script>
import addVideosModal from './../general/add-videos-modal.vue';

export default {
    components : {
        addVideosModal
    },
    emits : ['addVideos'],
    methods : {
        openModal (currentVideos) {
            let currentVideoIDs = currentVideos.map((item) => item.videoID);
            
            let filter = (item) => {
                return currentVideoIDs.indexOf(item.videoID) == -1;
            }

            this.$refs.addVideosModal.openModal(filter);
        },
        addVideos (selectedVideos) {
            this.$emit('addVideos', selectedVideos);

            this.$refs.addVideosModal.closeModal();
        }
    }
}
</script>