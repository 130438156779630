<template>
  <div class="border p-3" id="password">
    <div>
      <h3 class="mb-1">App Password</h3>
      <div class="d-flex align-items-center">
        <span class="fw-bold" v-show="!showPassword">∗∗∗∗∗∗∗∗</span>
        <span v-show="showPassword" v-text="password"></span>
        <button
          class="btn btn-link p-0 ms-2 hover-underline"
          @click="showPassword = !showPassword"
          v-text="showPassword ? 'Hide' : 'Show'"
        ></button>
      </div>
      <small class="d-block text-muted" v-text="lastModified"></small>
      <button
        class="btn btn-outline-primary d-block mt-2"
        data-bs-toggle="modal"
        data-bs-target="#changePasswordModal"
      >
        Change password
      </button>
    </div>
    <div class="modal fade" tabindex="-1" id="changePasswordModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change password</h5>
            <button class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <p class="text-muted">
              People already logged in will still be able to access videos until
              their session automatically expires after 14 days.
            </p>
            <input
              type="text"
              class="form-control"
              placeholder="New password"
              id="changePasswordInput"
              @keyup.enter="newPassword.length >= 8 ? changePassword() : null"
              v-model="newPassword"
            />
            <div class="d-flex justify-content-between align-items-center pt-1">
              <span class="text-muted">At least 8 characters</span>
              <button
                class="btn btn-primary"
                :disabled="newPassword.length < 8 || changePasswordLoading"
                @click="changePassword()"
              >
                Change password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#password {
  max-width: 350px;
  width: 100%;
}
</style>

<script>
import { Modal } from 'bootstrap'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      showPassword: false,
      newPassword: "",
      password : "...",
      lastModified : "...",
      changePasswordLoading : false
    };
  },
  async mounted() {
    document //2 bootstrap js instances - partially from navbar offcanvas
      .getElementById("changePasswordModal")
      .addEventListener("shown.bs.modal", function (event) {
        console.log("!!");
        console.log(event);
        document.getElementById("changePasswordInput").focus();
      });
    
    var password = (await this.$axios.get('/app/password')).data;
    this.password = password.password;
    this.lastModified = `Last changed ${dayjs(password.lastModified).format('MMMM D, YYYY')}`;
  },
  methods: {
    async changePassword() {
      this.changePasswordLoading = true;

      console.log("!");
      await this.$axios.put("/app/password", {
        password: this.newPassword,
      });
      var modal = Modal.getInstance(document.getElementById("changePasswordModal"));
      console.log(modal);
      modal.hide();
      this.password = this.newPassword;

      this.changePasswordLoading = false;
    },
  },
};
</script>