import { createRouter, createWebHistory } from 'vue-router'
import Signin from '@/views/Signin.vue'
import Console from '@/views/Console.vue'
import Group from '@/views/Group.vue'
import Editor from '@/views/Editor.vue'
import Videos from '@/views/Videos.vue'
import Profile from '@/views/Profile.vue'
import NotFound from '@/views/NotFound.vue'
import Email from '@/views/Email.vue'
import Bunkai from '@/views/Bunkai.vue'

import isAuthenticated from '@/scripts/isAuthenticated.ts'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect : '/console'
  },
  {
    path: '/signin',
    name: 'Signin',
    component : Signin,
    meta : { noSession : true, hideNavbar : true }
  },
  {
    path : '/console',
    name : 'Console',
    component : Console
  },
  {
    path : '/console/:groupID',
    name : 'Group',
    component : Group
  },
  {
    path : '/console/:groupID/editor',
    name : 'Editor',
    component : Editor
  },
  {
    path : '/videos',
    name : 'Videos',
    component : Videos
  },
  {
    path : '/profile',
    name : 'Profile',
    component : Profile
  },
  {
    path : '/email-link',
    name : 'Email Video Link',
    component : Email
  },
  {
    path : '/bunkai',
    name : 'Bunkai',
    component : Bunkai
  },
  { 
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach(async function (to, from, next) { //Redirect before any API calls
  console.log(`router.beforeEach (to ${to.fullPath})`);
  if (!await isAuthenticated() && to.meta.noSession != true) { //Requires a session and doesn't have one
    console.log("Redirecting to /signin (router.beforeEach)");
    var redirectURL = router.currentRoute.value.fullPath;
    console.log(router.currentRoute.value);
    if (redirectURL == "/console") {
      next("/signin"); //Defaults to redirect to /console
    } else {
      next(`/signin?redirect=${encodeURIComponent(router.currentRoute.value.fullPath)}`);
    }
  } else {
    console.log("Finished router.beforeEach");
    next();
  }
});
export default router