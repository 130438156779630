<template>
  <div class="modal fade" tabindex="-1" id="createGroupModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create group</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <label class="form-label" for="create-group-name">Name</label>
          <input
            class="form-control"
            type="text"
            id="create-group-name"
            v-model="name"
          />
          <label class="form-label mt-3" for="create-group-description"
            >Description</label
          >
          <textarea
            class="form-control"
            rows="5"
            style="resize: none"
            id="create-group-description"
            v-model="description"
            maxlength="150"
          ></textarea>

          <label for="create-group-image" class="form-label mt-3">Image</label>

          <div class="d-flex align-items-center justify-content-start">
          <button class="btn btn-outline-primary text-nowrap" @click="selectImage()">
            Choose file
          </button>
          <div class="fst-italic ms-3 text-truncate" v-text="fileName"></div>
          </div>
          <input
            class="d-none"
            type="file"
            id="create-group-image"
            ref="imageInput"
            accept=".jpg"
            @change="changeFileName()"
          />

          <button
            class="btn btn-primary float-end mt-2"
            @click="createGroup()"
            :disabled="loading || name.length == 0 || description.length == 0 || fileName == ''"
          >
            Create group
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { Storage } from "aws-amplify";

export default {
  data() {
    return {
      name: "",
      description: "",
      loading: false,
      fileName : ""
    };
  },
  emits: ["refresh"],
  methods: {
    async createGroup() {
      this.loading = true;

      var groupID = (
        await this.$axios.post("/groups", {
          name: this.name,
          description: this.description,
        })
      ).data.groupID;

      await Storage.put(`${groupID}.jpg`, this.$refs.imageInput.files[0]);

      var modal = Modal.getInstance(
        document.getElementById("createGroupModal")
      );
      modal.hide();

      this.loading = false;
      this.$emit("refresh");
    },
    changeFileName () {
      this.fileName = this.$refs.imageInput.files[0].name;
    },
    selectImage() {
      this.$refs.imageInput.click();
    },
  },
  mounted() {
    document
      .getElementById("createGroupModal")
      .addEventListener("shown.bs.modal", function () {
        document.getElementById("create-group-name").focus();
      });
  },
};
</script>