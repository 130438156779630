<template>
  <div>
    <div class="container-fluid pt-4">
      <div class="row gx-5 gy-5 m-0">
        <div class="col-12 mt-0">
          <h1 class="mb-4" v-text="nameHeader"></h1>
        </div>

        <div class="col-12 col-md-6 col-lg-8 mt-0">
          <div class="card shadow px-4 py-3 h-100">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <div class="fw-bold">Name</div>
              <button
                v-show="!editing"
                class="btn btn-link p-0 hover-underline"
                @click="openInfo()"
              >
                Edit
              </button>
            </div>
            <div v-text="name" v-show="!editing"></div>
            <input
              type="text"
              class="form-control"
              v-show="editing"
              v-model="name"
              ref="name"
            />
            <div class="fw-bold mt-3 mb-1">Description</div>
            <div v-text="description" v-show="!editing"></div>
            <textarea
              class="form-control"
              rows="5"
              style="resize: none"
              id="create-group-description"
              v-model="description"
              v-show="editing"
              maxlength="150"
            ></textarea>
            <div class="d-flex justify-content-end mt-2">
              <button
                v-show="editing"
                :disabled="name.length == 0 || description.length == 0"
                class="btn btn-primary float-end px-3"
                @click="saveInfo()"
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mt-md-0">
          <div class="card shadow px-4 py-3">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <div class="fw-bold">Image</div>
              <button class="btn btn-link p-0 hover-underline" @click="selectImage()">
                Change
              </button>
              <input
                class="d-none"
                type="file"
                ref="imageInput"
                accept=".jpg"
                @change="uploadImage()"
              />
            </div>
            <div class="ratio ratio-21x9">
              <img
                :src="
                  'https://group-images.kovarslaguna.com/' +
                  $route.params.groupID +
                  '.jpg' + ('?rand=' + imgDate)
                "
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow px-4 py-3 h-100">
            <div class="d-flex align-items-center justify-content-between">
              <div class="fw-bold">Content</div>
              <router-link
                :to="'/console/' + $route.params.groupID + '/editor'"
                class="btn btn-primary"
              >
                Open in Editor

                <font-awesome-icon
                  :icon="['fas', 'external-link-alt']"
                  class="ps-1"
                ></font-awesome-icon>
              </router-link>
            </div>
            <div
              class="text-center fst-italic my-3"
              v-if="!loading && toc.length == 0"
            >
              No topics yet, add some in the editor above!
            </div>
            <loading v-if="loading" />
            <table
              class="table align-middle mx-auto mt-3"
              style="max-width: 600px"
              v-show="!loading && toc.length > 0"
            >
              <thead>
                <tr>
                  <td class="fw-bold" colspan="3">Videos</td>
                </tr>
              </thead>
              <tbody>
                <template v-for="topic in toc" :key="topic.topicID">
                  <tr>
                    <td colspan="2" v-text="topic.name"></td>
                  </tr>
                  <tr v-for="video in topic.videos" :key="video.videoID">
                    <td class="border-0"></td>
                    <td v-text="video.name"></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify';
import loading from '@/components/loading.vue';



export default {
  components : {
    loading,
  },
  data() {
    return {
      name: "---",
      nameHeader: "---",
      description: "---",
      loading: true,
      toc: [],
      editing: false,
      imgDate : ""
    };
  },
  methods: {
    openInfo() {
      this.editing = true;
      this.$nextTick(function () {
        this.$refs.name.focus();
        console.log("!");
      });
    },
    async saveInfo() {
      this.editing = false;
      this.nameHeader = this.name;

      await this.$axios.patch(`/groups/${this.$route.params.groupID}`, {
        name: this.name,
        description: this.description,
      });
    },
    selectImage () {
      this.$refs.imageInput.click();
    },
    async uploadImage () {
      await Storage.put(`${this.$route.params.groupID}.jpg`, this.$refs.imageInput.files[0]);
      this.imgDate = Date.now();
    }
  },
  async mounted() {
    this.loading = true;

    this.imgDate = Date.now();
    
    var data = (await this.$axios.get(`/groups/${this.$route.params.groupID}`))
      .data;
    console.log(data);

    this.name = data.name;
    this.nameHeader = data.name;
    this.description = data.description;

    this.toc = (
      await this.$axios.get(`/groups/${this.$route.params.groupID}/toc`)
    ).data;

    this.loading = false;
  },
};
</script>