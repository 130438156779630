<template>
  <div class="modal fade" tabindex="-1" id="addVideosModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add videos</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <loading v-if="loading" />
          <div class="text-center text-muted fst-italic" v-if="!loading && videos.length == 0">No videos!</div>
          <div v-for="video in videos" :key="video.videoID">
            <div class="form-check">
              <input class="form-check-input" :checked="video.selected" :id="'add-video-check-' + video.videoID"
                type="checkbox" @click="video.selected = !video.selected" />
              <label class="form-check-label" :for="'add-video-check-' + video.videoID">
                {{ video.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span class="text-muted pe-1">{{ selectedVideoCount }}
            {{ selectedVideoCount == 1 ? "video" : "videos" }} selected</span>
          <button class="btn btn-primary" :disabled="videos.length == 0 || selectedVideoCount == 0" @click="addVideos()">
            Add videos
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

import loading from "@/components/loading.vue";

export default {
  components: {
    loading,
  },
  data() {
    return {
      videos: [],
      loading: true,
    };
  },
  emits: ["addVideos"],
  computed: {
    selectedVideos() {
      return this.videos.filter(function (item) {
        return item.selected == true;
      });
    },
    selectedVideoCount() {
      return this.selectedVideos.length;
    },
  },
  methods: {
    async openModal(filter) {
      var modal = new Modal(document.getElementById("addVideosModal"));
      modal.show();

      this.loading = true;
      var videos = (await this.$axios.get("/videos")).data.Items;
      this.loading = false;

      console.log(videos);
      this.videos = videos.map(function (item) {
        return {
          //Filters out URL, content
          name: item.name,
          videoID: item.videoID,
          selected: false,
        };
      });

      this.videos = videos.filter(filter);
    },
    
    closeModal() {
      var modal = Modal.getInstance(document.getElementById("addVideosModal"));
      modal.hide();
    },
    
    addVideos() {
      this.$emit("addVideos", this.selectedVideos);
    }
  },
};
</script>