<template>
  <div class="h-100">
    <navbar v-if="$route.meta.hideNavbar != true" />
    <router-view class="h-100" />
    <transition name="err-alert">
      <div
        class="
          alert alert-warning alert-dismissible
          position-absolute
          overflow-auto
        "
        v-show="errAlert"
        id="err-alert"
        role="alert"
      >
        Sorry, something went wrong!
        <button class="btn btn-link py-0" @click="showErr = !showErr">
          Error info</button
        ><br />
        {{ showErr ? errMessage : "" }}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="errAlert = false"
        ></button>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
//Import scss
@import "./scss/bootstrap.scss";
</style>

<script>
import { app } from "@/main.js";
import Amplify from "aws-amplify";

import navbar from "@/components/navbar.vue";


export default {
  data() {
    return {
      errAlert: false,
      errMessage: "",
      showErr : false
    };
  },
  components: {
    navbar,
  },
  mounted() {
    var vm = this;
    app.config.errorHandler = function (err) {
      //Including axios errors :) catches await axios
      console.log("Error handler!"); //TODO - add error message popup
      console.log(err);
      vm.errAlert = true;
      vm.errMessage = err;
    };

    Amplify.configure({
      Auth: {
        identityPoolId: "us-west-1:709db042-b224-42e3-acbd-3e7128e81d15",
        region: "us-west-1",
        userPoolId: "us-west-1_Hpgb3Gzmf",
        userPoolWebClientId: "16mcdnv3uin9vte549keejciea", //App client?
      },
      Storage: {
        AWSS3: {
          bucket: "group-images.kovarslaguna.com",
          region: "us-west-1",
        },
        customPrefix : {
          public : ''
        }
      },
    });
  },
};
</script>

<style>
html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#app {
  height: 100%;
  overflow: auto; /* Doesn't allow scroll from offcanvas Home page without overflow auto :) */
}

#err-alert {
  bottom: 20px;
  left: 20px;
  max-width: 50%;
  max-height: 30%; /* TODO - change units? */
  word-break: break-all; /* Change? More options too */
}

.err-alert-enter-active {
  transition: opacity 0.5s ease;
}

.err-alert-enter-from,
.err-alert-leave-to {
  opacity: 0;
}
</style>
