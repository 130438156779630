<template>
  <div>
    <loading v-if="loading" class="pb-5" />
    <div v-show="!loading" class="py-5 text-center">
      <h2>All finished! 👏</h2>
      <div>
        You can now add your video in a topic using the editor.
        <br>
        Feel free to close this screen now, or 
        <button class="btn btn-link hover-underline p-0 align-baseline" @click="reset()">upload another video</button>.
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  components: {
    loading,
  },
  data () {
    return {
      loading : true
    };
  },
  emits : ["reset"],
  async mounted () {
    await this.$axios.post('/videos', {
      name : this.videoData.name,
      content : this.videoData.content,
      URL : this.videoData.url //Different capitalization!
    });
    this.loading = false;
  },
  methods : {
    reset () {
      this.$emit("reset");
    }
  },
  props : {
      videoData : Object
  }
};
</script>