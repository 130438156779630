<template>
  <div>
    <h3>Enter your email</h3>
    <label for="sidebar-email" class="form-label">Email address</label>
    <input
      type="text"
      class="form-control"
      placeholder="name@example.com"
      id="sidebar-email"
      v-model="email"
      @keyup.enter="next"
    />

    <div class="text-danger mt-1" v-text="invalidEmail ? 'Sorry, please enter a valid email address!' : '&nbsp;'"></div>

    <div class="d-grid gap-2 mt-2">
      <button
        class="btn btn-primary"
        @click="next()"
        :disabled="email.length == 0"
      >
        Next
      </button>
    </div>
    <div class="d-flex justify-content-center mt-1">
        <button class="btn btn-link hover-underline" @click="previous()">Change video</button>
    </div>
  </div>
</template>

<script>
import * as validator from 'email-validator';

export default {
  data() {
    return {
      email: "",
      invalidEmail : false
    };
  },
  emits: ["next", "previous"],
  methods: {
    next() {
      if (!validator.validate(this.email)) {
          this.invalidEmail = true;
          return;
      }
      this.$emit("next", this.email);
    },
    previous () {
        this.$emit("previous");
    }
  },
};
</script>