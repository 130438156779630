function index(state, payload) {
    return state.topics.findIndex(function (item) {
        return item.topicID == payload.topicID;
    });
}

export default {
    namespaced: true,
    state: {
        topics: [],
        expandedTopic: "",
        saving: false
    },
    getters: {
        topic: (state) => (topicID) => {
            return state.topics[index(state, { topicID: topicID })];
        },
        videos: (state) => (topicID) => {
            return state.topics[index(state, { topicID: topicID })].videos;
        },
        videoIDs: (state) => (topicID) => { //Add videos modal, as a list
            return state.topics[index(state, { topicID: topicID })].videos.map(function (item) {
                return item.videoID
            });
        }
    },
    mutations: {
        editTopics(state, payload) {
            state.topics = payload;
        },
        loadTopicVideos(state, payload) {
            // { topicID : "", videos : [] }
            state.topics[index(state, payload)].videos = payload.videos;
        },
        updateName(state, payload) {
            // { topicID : "", name : "" }
            console.log(`mutations vm ${this._vm}`);
            state.topics[index(state, payload)].name = payload.name;
        },
        updateContent(state, payload) {
            // { topicID : "", content : "" }
            console.log(index(state, payload));
            state.topics[index(state, payload)].content = payload.content;
        },
        updateVideos(state, payload) {
            console.log(payload);
            state.topics[index(state, payload)].videos = payload.videos;
        },
        addVideos(state, payload) {
            state.topics[index(state, payload)].videos.push(...payload.videos);
            //push uses multiple arguments to add items - push(1, 2, 3) - so use ... to convert list into multiple arguments
        },
        expand(state, payload) {
            state.expandedTopic = payload;
        },
        deleteTopic(state, payload) {
            state.topics.splice(index(state, { topicID: payload }), 1);
        },
        removeVideo(state, payload) { // { topicID : "", videoID : "" }
            var videoIndex = state.topics[index(state, payload)].videos.findIndex(function (item) {
                return item.videoID == payload.videoID;
            });
            state.topics[index(state, payload)].videos.splice(videoIndex, 1);
        },
        newBlankTopic(state, topicID) {
            state.topics.push({
                name: "New topic",
                content: "*Add a description here!*",
                topicID: topicID,
                videos: []
            });
        }
    },
}