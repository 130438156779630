<template>
    <div class="row">
        <div class="pb-2">
            Hey, let's get started! How do you want to upload your video?
        </div>
        <div class="col-12 col-md-6 text-center d-flex justify-content-center p-4 cursor-pointer border-bottom border-bottom-md-0 border-end-md" @click="select('file')">
            <div>
                <h3>Upload a file</h3>
                <div>Use a video file from your computer</div>
            </div>
        </div>
        <div class="col-12 col-md-6 text-center d-flex justify-content-center p-4 cursor-pointer" @click="select('link')">
            <div>
                <h3>Use a Vimeo link</h3>
                <div>Already have a video uploaded? Copy and paste a Vimeo link</div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    emits : ["next"],
    methods : {
        select (type) {
            this.$emit("next", {
                component : "select-upload",
                type : type
            });
        }
    }
}
</script>