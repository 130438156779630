<template>
    <div class="modal fade" tabindex="-1" id="deleteGroupModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete group</h5>
            <button class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete this group?</p>
          </div>
          <div class="modal-footer">
          <button class="btn btn-link hover-underline" data-bs-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-primary px-3" :disabled="loading" @click="deleteGroup()">
            Delete
          </button>
        </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  data () {
    return {
      groupID : "",
      loading : false
    };
  },
  emits : ['refresh'],
  methods: {
    openModal(groupID) {
      this.groupID = groupID;
      var modal = new Modal(document.getElementById("deleteGroupModal"));
      modal.show();
    },
    async deleteGroup() {
      this.loading = true;
      await this.$axios.delete(`/groups/${this.groupID}`);
      var modal = Modal.getInstance(document.getElementById("deleteGroupModal"));
      modal.hide();

      this.$emit('refresh');
      this.loading = false;
    },
  },
};
</script>