<template>
  <div>
    <div class="text-center">
      <h1 class="mb-3">Admin Console Sign In</h1>
      <p class="mb-0">Hey, good to see you again!</p>
      <a href="https://kovarslaguna.com">Back to main site</a>
    </div>
    <div class="mt-3">
      <div class="mb-3">
        <div class="alert alert-danger" v-show="err != ''" v-text="err"></div>
        <label class="form-label" for="username"> Email </label>
        <input
          type="text"
          id="username"
          placeholder="hanshi@kovars.com"
          class="form-control"
          v-model="username"
          autofocus
          maxlength="128"
          @keyup.enter="signIn()"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="password"> Password </label>
        <input
          type="password"
          id="password"
          class="form-control"
          v-model="password"
          maxlength="99"
          @keyup.enter="signIn()"
        />
      </div>
    </div>
    <div class="d-grid gap-2">
      <button class="btn btn-primary" @click="signIn()" :disabled="loading">Sign in</button>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      username: "",
      password: "",
      err: "",
      loading : false
    };
  },
  emits: ["changePassword", "redirect"],
  methods: {
    showError (msg) {
      this.err = msg;
      this.loading = false;
    },
    async signIn() {
      if (this.username == "") {
        this.showError("Hey, don't forget a username!");
        return;
      } else if (this.password == "") {
        this.showError("Hey, don't forget a password!");
        return;
      }
      
      this.loading = true;

      var user;
      try {
        user = await Auth.signIn(this.username, this.password);
        console.log(user);
      } catch (err) {
        switch (err.name) {
          case "UserNotFoundException":
            this.showError("Whoops, we can't find that username!");
            break;
          case "NotAuthorizedException":
            this.showError("Whoops, wrong password!");
            break;
          default:
            this.showError("Hmm, something went wrong!");
            throw err;
        }
        return;
      }
      
      if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
        console.log("Change password!");
        this.$emit("changePassword", user);
      } else {
        this.$emit("redirect");
      }
    },
  },
};
</script>