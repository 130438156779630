<template>
  <div>
    <div class="d-flex align-items-center mb-2" v-if="toggleEdit">
      <h6 class="mb-0" v-text="label"></h6>
      <button
        class="btn btn-link p-0 ms-2 hover-underline"
        @click="openEdit()"
        v-show="!edit"
      >
        Edit
      </button>
    </div>
    <div v-if="toggleEdit" v-show="!edit" v-html="markdownContent(value)"></div>
    <div v-show="edit || !toggleEdit">
      <textarea
        class="form-control"
        rows="5"
        style="resize: none"
        ref="textarea"
        :id="textareaID ? textareaID : null"
        :value="value"
        @input="$emit('update:value', $event.target.value)"
        :maxlength="maxlength !== 0 ? maxlength : null"
      ></textarea>
      <div class="d-flex mt-2">
        <div class="input-group">
          <button
            class="btn btn-outline-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-animation="false"
            title="Bold"
            @click="addMarkdown('****', true)"
          >
            <font-awesome-icon
              :icon="['fas', 'bold']"
              class="mx-1"
            ></font-awesome-icon>
          </button>
          <button
            class="btn btn-outline-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-animation="false"
            title="Italic"
            @click="addMarkdown('**', true)"
          >
            <font-awesome-icon
              :icon="['fas', 'italic']"
              class="mx-1"
            ></font-awesome-icon>
          </button>
          <button
            class="btn btn-outline-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-animation="false"
            title="Link"
            @click="addMarkdown('[Google](https://google.com)', false)"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              class="mx-1"
            ></font-awesome-icon>
          </button>
        </div>
        <button
          v-if="toggleEdit"
          class="btn btn-primary"
          @click="save()"
          :disabled="value.length == 0"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";

import markdown from "@/mixins/markdown.ts";

export default {
  data() {
    return {
      edit: false,
    };
  },
  emits: ["save", "update:value"],
  methods: {
    addMarkdown(text, surround) {
      //Text should be the entire markdown, the cursor will go halfway in between
      var elem = this.$refs.textarea;
      var curPos = elem.selectionStart;
      var value = elem.value;
      elem.value = value.slice(0, curPos) + text + value.slice(curPos);
      elem.focus();
      var newPos = surround ? curPos + text.length / 2 : curPos + text.length;
      elem.setSelectionRange(newPos, newPos);
    },
    save() {
      this.$emit("save", this.value);
      this.edit = false;
    },
    openEdit() {
      this.edit = true;
      this.$nextTick(function () {
        this.$refs.textarea.focus();
      });
    },
    valueLength(event) {
      console.log(event);
    },
  },
  mixins: [markdown],
  mounted() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
  props: {
    value: {
      default: "",
      type: String,
    },
    label: String,
    toggleEdit: {
      //If false, keeps editor always open and hides done button
      //Then, use refs to get value variable
      default: true,
      type: Boolean,
    },
    textareaID: {
      default: "",
      type: String,
    },
    maxlength : {
      default : 0,
      type : Number
    }
  },
};
</script>