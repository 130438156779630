<template>
  <div class="modal fade" tabindex="-1" id="deleteTopicModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete topic</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete this topic?
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-link hover-underline" data-bs-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-primary px-3" @click="deleteTopic()">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";


export default {
  data () {
    return {
      topicID : ""
    };
  },
  methods: {
    async openModal(topicID) {
      this.topicID = topicID;
      var modal = new Modal(document.getElementById("deleteTopicModal"));
      modal.show();
    },
    async deleteTopic() {
      this.$store.commit("editor/deleteTopic", this.topicID);

      var modal = Modal.getInstance(document.getElementById("deleteTopicModal"));
      modal.hide();

      await this.$axios.delete(`/groups/${this.$route.params.groupID}/topics/${this.topicID}`);
    },
  },
};
</script>