<template>
    <video-item :data="data" @removeVideo="removeVideo()" />
</template>

<script>
import videoItem from './../general/video-item.vue';

export default {
    components: {
        videoItem
    },
    props: {
        data: Object,
        topicID: String
    },
    methods: {
        async removeVideo() {
            console.log(`topicID : ${this.topicID}, videoID : ${this.data.videoID}`);
            this.$store.commit("editor/removeVideo", {
                topicID: this.topicID,
                videoID: this.data.videoID
            });

            await this.$axios.delete(`/groups/${this.$route.params.groupID}/topics/${this.topicID}/videos/${this.data.videoID}`);
        }
    }
}
</script>