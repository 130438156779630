<template>
  <div class="modal fade" tabindex="-1" id="importTopicsModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Import topics</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <loading v-if="loading" />
          <div class="text-center text-muted fst-italic" v-if="!loading && topics.length == 0">No topics to import!</div>
          <div v-for="topic in topics" :key="topic.groupID + '-' + topic.topicID">
            <div class="form-check">
              <input
                class="form-check-input"
                :checked="topic.selected"
                :id="'import-topic-check-' + topic.groupID + '-' + topic.topicID"
                type="checkbox"
                @click="topic.selected = !topic.selected"
              />
              <label
                class="form-check-label"
                :for="'import-topic-check-' + topic.groupID + '-' + topic.topicID"
              >
                {{ topic.name }}
                <span class="text-muted"> ({{ groupName(topic.groupID) }})</span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span class="text-muted pe-1"
            >{{ selectedTopicCount }}
            {{ selectedTopicCount == 1 ? "topic" : "topics" }} selected</span
          >
          <button
            class="btn btn-primary"
            :disabled="topics.length == 0 || selectedTopicCount == 0 || loading"
            @click="importTopics()"
          >
            {{ loading ? "Importing..." : "Import topics" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

import loading from "@/components/loading.vue";

export default {
  components: {
    loading,
  },
  data() {
    return {
      groups : [],
      topics: [],
      loading: true,
    };
  },
  computed: {
    selectedTopics() {
      return this.topics.filter(function (item) {
        return item.selected == true;
      });
    },
    selectedTopicCount() {
      return this.selectedTopics.length;
    },
  },
  emits : ["refreshData"],
  methods: {
    groupName (groupID) {
      var index = this.groups.findIndex(function (item) {
        return item.groupID == groupID;
      });
      return this.groups[index].name;
    },
    async openModal() {
      var modal = new Modal(document.getElementById("importTopicsModal"));
      modal.show();

      this.loading = true;

      var vm = this;
      this.groups = (await this.$axios.get("/groups")).data.Items.flatMap(function (item) {
        if (item.groupID == vm.$route.params.groupID) {
          console.log("Current group");
          return [];
        } else {
          return [
            {
              name : item.name,
              groupID : item.groupID
            }
          ];
        }
      });

      var groups = this.groups.map(function (item) { //just groupID's
        return item.groupID;
      });

      console.log(`Groups: ${groups}`);
      var topics = [];

      
      for (var i = 0; i < groups.length; i++) {
        (await vm.$axios.get(`/groups/${groups[i]}/topics`)).data.topics.forEach(function (item) {
          topics.push({
            //Filter out content, order, and add groupID
            name: item.name,
            topicID: item.topicID,
            groupID: groups[i],
            selected : false
          });
        });
      }
      this.topics = topics;
      this.loading = false;
    },
    async importTopics() {
      /*this.$store.commit("importTopics", {
        topicID: this.topicID,
        videos: this.selectedVideos.map(function (item) { //filters out selected : true
          return {
            name: item.name,
            videoID: item.videoID
          };
        }),
      });*/

      this.loading = true;

      for (var i = 0; i < this.selectedTopics.length; i++) {
        await this.$axios.post(`/groups/${this.$route.params.groupID}/topics/import`, {
          templateGroupID : this.selectedTopics[i].groupID,
          templateTopicID : this.selectedTopics[i].topicID
        });
      }

      this.loading = false;

      var modal = Modal.getInstance(
        document.getElementById("importTopicsModal")
      );
      modal.hide();
      
      this.$emit('refreshData');
    },
  },
};
</script>