<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="createVideoModal"
    @dragover.prevent
    @drop.prevent
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add video</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <select-upload v-if="step == 1" @next="next" />
          <upload-file
            v-if="step == 2 && type == 'file'"
            @back="back()"
            @next="next"
          />
          <upload-link
            v-if="step == 2 && type == 'link'"
            @back="back()"
            @next="next"
          />
          <video-info v-if="step == 3" @next="next" />
          <finish v-if="step == 4" :videoData="video" @reset="reset()" />
        </div>
        <div
          class="modal-footer d-flex justify-content-right"
          v-if="step !== 4"
        >
          <div>Step {{ step }}/3</div>
          <div class="progress flex-grow-1" style="max-width: 150px;">
            <div
              class="progress-bar"
              role="progressbar"
              :style="
                'width: ' + (step == 1 ? '33' : step == 2 ? '66' : '100') + '%'
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectUpload from "@/components/videos/create-video/select-upload.vue";
import uploadFile from "@/components/videos/create-video/upload-file.vue";
import uploadLink from "@/components/videos/create-video/upload-link.vue";
import videoInfo from "@/components/videos/create-video/video-info.vue";
import finish from "@/components/videos/create-video/finish.vue";

export default {
  components: {
    selectUpload,
    uploadFile,
    uploadLink,
    videoInfo,
    finish,
  },
  data() {
    return {
      step: 1,
      type: "", //"file" or "link"
      video: {},
    };
  },
  emits: ["refresh"],
  methods: {
    next(data) {
      switch (data.component) {
        case "select-upload":
          this.type = data.type;
          break;
        case "upload-file":
          this.video.url = data.url;
          break;
        case "upload-link":
          console.log(data.url);
          this.video.url = data.url;
          break;
        case "video-info":
          this.video.name = data.name;
          this.video.content = data.content;
          break;
        default:
          throw "Invalid component name!";
      }

      this.step++;
    },
    back() {
      this.step--;
    },
    reset() {
      this.video = {};
      this.step = 1;
      this.type = ""; //Components use v-if, so they should be destroyed/recreated if reset
    },
  },
  mounted() {
    var vm = this;
    document
      .getElementById("createVideoModal")
      .addEventListener("hidden.bs.modal", function () {
        vm.reset();
        vm.$emit("refresh");
      });
  },
};
</script>