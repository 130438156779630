<template>
  <div class="modal fade" tabindex="-1" id="editVideoModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit video</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <loading v-if="loading" />
          <div v-if="!loading" class="row px-2 pt-3">
            <div class="col-12 col-lg-6 pb-3 pb-lg-0">
              <input-editor :initialValue="data.name" @save="saveName" :maxlength="150" />
              <markdown-editor
                class="mt-3"
                v-model:value="data.content"
                label="Description"
                @save="saveContent"
                :maxlength="1500"
              />
            </div>
            <div class="col-12 col-lg-6">
              <div class="ratio ratio-16x9">
                <iframe
                  :src="
                    this.data.URL
                      ? 'https://player.vimeo.com/video/' +
                        this.data.URL +
                        '?badge=0&autopause=0&player_id=0'
                      : ''
                  "
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div>
                <input-editor class="mt-2 float-end" :initialValue="data.URL" @save="saveURL" buttonText="Edit URL" :showText="false" :inputGroupText="'https://vimeo.com/'" :maxlength="20"></input-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

import loading from "@/components/loading.vue";

import inputEditor from "@/components/input-editor.vue";
import markdownEditor from "@/components/markdown-editor.vue";

export default {
  components: {
    loading,
    markdownEditor,
    inputEditor,
  },
  data() {
    return {
      data: {}, //Name isn't updated as data changes (only for initial value :) ), but description and URL are
      loading: true,
    };
  },
  emits : ['refresh'],
  methods: {
    async openModal(videoID) {
      var modal = new Modal(document.getElementById("editVideoModal"));
      modal.show();

      this.loading = true;
      this.data = (await this.$axios.get(`/videos/${videoID}`)).data;
      this.loading = false;
    },
    saveName(name) {
      this.$axios.patch(`/videos/${this.data.videoID}`, { name : name });
    },
    saveContent(content) {
      this.$axios.patch(`/videos/${this.data.videoID}`, { content : content });
    },
    saveURL (url) {
      this.$axios.patch(`/videos/${this.data.videoID}`, { URL : url });
      this.data.URL = url;
    }
  },
  mounted () {
    var vm = this;
    document.getElementById("editVideoModal").addEventListener("hidden.bs.modal", function () {
      vm.$emit("refresh");
    });
  }
};
</script>